import React, { useState, useEffect, useCallback } from "react";
import URL from "../../configuration/config";
import Loading from "../Elements/Loading";
import { toast, ToastContainer } from "react-toastify";

export default function System() {
  const details = [
    {
      name: "company_name",
      settings: "",
      type: "text",
    },
    {
      name: "company_email",
      settings: "",
      type: "email",
    },
    {
      name: "default_email",
      settings: "",
      type: "email",
    },
    {
      name: "street_address",
      settings: "",
    },
    {
      name: "company_number",
      settings: "",
      type: "text",
    },
    {
      name: "opening_days",
      settings: "",
      type: "text",
    },
    {
      name: "opening_hours",
      settings: "",
      type: "time",
    },
    {
      name: "closing_hours",
      settings: "",
      type: "time",
    },
    {
      name: "additional_opening_days",
      settings: "",
      type: "text",
    },
    {
      name: "additional_opening_hours",
      settings: "",
      type: "time",
    },
    {
      name: "additional_closing_hours",
      settings: "",
      type: "time",
    },
    {
      name: "city",
      settings: "",
      type: "text",
    },
    {
      name: "footer_text",
      settings: "",
      type: "text",
    },
  ];
  const [loading, setLoading] = useState(false);
  const [currentSettings, setCurrentSettings] = useState([]);
  const [initialSettings, setInitialSettings] = useState([]);
  const [editedFieldIds, setEditedFieldIds] = useState([]);

  const getSystemSettings = useCallback(() => {
    setLoading(true);
    URL.get("/system-settings/all")
      .then((response) => {
        setLoading(false);
        console.log(response);
        setInitialSettings(response.data.data.system_settings);
        setCurrentSettings(response.data.data.system_settings);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getSystemSettings();
  }, [getSystemSettings]);

  const Validation = () => {
    let isValid = true;
    console.log(currentSettings);

    const settingsArray = Object.entries(currentSettings).map(
      ([key, value]) => {
        return { name: key, settings: value };
      }
    );
    console.log(settingsArray);
    if (settingsArray.length === 0 || settingsArray.length <= 5) {
      toast.error("Please fill up all the fields");
      isValid = false;
    }

    return isValid;
  };

  const handleInputChange = (e, name) => {
    console.log(name);
    const { value } = e.target;
    setCurrentSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
    console.log(currentSettings);
  };

  const handleInputEditChange = (e, item, index) => {
    const updatedSettings = [...initialSettings];
    updatedSettings[index].settings = e.target.value;
    setInitialSettings(updatedSettings);
    const uniqueIds = new Set(editedFieldIds);
    uniqueIds.add(item);

    // Update the state of editedFieldIds with the new Set of unique IDs
    setEditedFieldIds(Array.from(uniqueIds));
  };

  const handleSave = () => {
    const Valid = Validation();
    if (Valid) {
      setLoading(true);
      const settingsArray = Object.entries(currentSettings).map(
        ([key, value]) => {
          return { name: key, settings: value };
        }
      );
      console.log(settingsArray);
      const formData = new FormData();
      settingsArray.map((item, index) => {
        formData.append(`system_settings[${index}][name]`, item.name);
        formData.append(`system_settings[${index}][settings]`, item.settings);
        return null;
      });
      URL.post("/system-settings/add-or-update-in-batch", formData)
        .then((response) => {
          if (response.status === 200) {
            setInitialSettings(response.data.data.system_settings);
            setLoading(false);
            toast.success(response.data.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const handleUpdate = () => {
    setLoading(true);
    const formData = new FormData();
    editedFieldIds.map((item, index) => {
      console.log(item);
      formData.append(`system_settings[${index}][name]`, item.name);
      formData.append(`system_settings[${index}][settings]`, item.settings);
      return null;
    });
    URL.post("/system-settings/add-or-update-in-batch", formData)
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          setInitialSettings(response.data.data.system_settings);
          setLoading(false);
          toast.success("Updated Successfully");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="card">
          <div className="d-flex justify-content-between">
            <h4 className="pt-4 px-4">System Settings</h4>
          </div>
          <hr className="mx-4" />
          <div className="py-4 px-4">
            {initialSettings.length === 0 ? (
              <div className="row mb-4">
                {details.map((data, index) => {
                  return (
                    <div className="col-md-4 mb-3" key={index}>
                      <label htmlFor="name" className="form-label pe-2">
                        {data?.name
                          .split("_")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")}{" "}
                      </label>
                      <input
                        type={data.type}
                        className="form-control"
                        required
                        name={data.name}
                        onChange={(e) => handleInputChange(e, data.name)}
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="row mb-4">
                {initialSettings.map((item, index) => {
                  return (
                    <div className="col-md-4 mb-3" key={index}>
                      <label htmlFor="name" className="form-label pe-2">
                        {item?.name
                          .split("_")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")}{" "}
                      </label>
                      {item.name === "opening_hours" ||
                      item.name === "closing_hours" ||
                      item.name === "additional_opening_hours" ||
                      item.name === "additional_closing_hours" ? (
                        <input
                          type="time"
                          className="form-control"
                          required
                          name={item.name}
                          value={item.settings}
                          onChange={(e) =>
                            handleInputEditChange(e, item, index)
                          }
                        />
                      ) : (
                        <input
                          type="text"
                          className="form-control"
                          required
                          name={item.name}
                          value={item.settings}
                          onChange={(e) =>
                            handleInputEditChange(e, item, index)
                          }
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            )}
            {initialSettings.length === 0 ? (
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-primary mt-3"
                  onClick={handleSave}
                >
                  Save
                </button>
              </div>
            ) : (
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-primary mt-3"
                  onClick={handleUpdate}
                >
                  Update
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
}
