import axios from "axios";
import Cookies from "js-cookie";
import { domainURL } from "./domainURL";

var token;
if (!Cookies.get("token")) {
  token = ""; // Set the token to an empty string or handle it based on your logic
} else {
  token = Cookies.get("token") ??  null;
}

const baseURL = axios.create({
  baseURL: domainURL + "api/v1",
  headers: {
    Authorization: "Bearer " + token,
  },
});

baseURL.defaults.headers.post["Content-Type"] = "application/json";
baseURL.defaults.headers.post["Accept"] = "application/json";

baseURL.interceptors.response.use(
  (config) => {
    const token = Cookies.get("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.data) {
      console.log(error);

      if (error.response.status === 401) {
        console.log("hello");
        Cookies.remove("token");
        // Cookies.set("token", null);
        window.location.href = "/login";
        // return <Navigate to="/login" />;
      }

      if (error.response.status === 404) {
        // router.push({ name: "NotFound" });
      }
      if (error.response.status === 403) {
        // router.push({ name: "not_authorize" });
      }

      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  }
);

export default baseURL;
