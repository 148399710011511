import React, { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
// import ReactCrop from "react-image-crop";
// import "react-image-crop/dist/ReactCrop.css";
// import ImageCropDialog from "./ImageCropDialog";

const baseStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  transition: "border .3s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};
const rejectStyle = {
  borderColor: "red",
};

function DropzoneComponent({ files, setFiles, imgRef }) {
  // const [crop, setCrop] = useState({ aspect: 16 / 9 });

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file, i) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    [setFiles]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: { "image/*": [".jpeg", ".png", ".jpg"] },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject]
  );

  const fileRejectionItems = fileRejections.map(({ file, errors, i }) => (
    <li key={i}>
      {file.path} - {file.size} bytes
      <ul>
        {errors.map((e, i) => (
          <li key={i}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  const thumbs = files.map((file) => {
    return (
      <div key={file.name}>
        <div className="card image-card mt-3">
          <img
            src={file.preview}
            alt={file.name}
            className="card-edit-image"
            ref={imgRef}
          />
        </div>
      </div>
    );
  });
  console.log(files);

  return (
    <section>
      <div>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} multiple={false} />
          <div>Drag and drop your images here.</div>
        </div>
        <aside>{thumbs}</aside>
        <ul>{fileRejectionItems}</ul>
      </div>
    </section>
  );
}

export default DropzoneComponent;
