import React, { useState, useEffect } from "react";
import { FaEdit } from "react-icons/fa";
import { MdOutlineDeleteOutline } from "react-icons/md";
import DataTable from "react-data-table-component";
import URL from "../../configuration/config";
import Loading from "../Elements/Loading";
import Modal from "react-bootstrap/Modal";
import { AiOutlineSearch } from "react-icons/ai";
import DropzoneComponent from "../Dropbox/DropzoneComponent";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { categoryImageURL } from "../../configuration/domainURL";
import DeleteModal from "../Elements/DeleteModal";

export default function ViewCategroy() {
  // const [categoryImage, setCategoryImage] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [categoryNameError, setCategoryNameError] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [categoryEdit, setCategoryEdit] = useState("");
  const [delShow, setDelShow] = useState(false);
  const [delID, setDelID] = useState(null);
  const [search, setSearch] = useState("");
  const [files, setFiles] = useState([]);
  const [rows, setRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getCategoryData(currentPage, rowsPerPage);
  }, [currentPage, rowsPerPage]);

  const getCategoryData = (page, rowsPerPage) => {
    setLoading(true);
    URL.get("/categories", {
      params: {
        limit: rowsPerPage,
        page: page,
      },
    })
      .then((result) => {
        if (result.status === 200) {
          setLoading(false);
          setCategoryData(result.data.data.categories);
          console.log(result);
          setRows(result.data.data.totalRows);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  // useEffect(() => {
  //   setFilterCategory(
  //     categoryData.filter((row) => {
  //       return row.name.toLowerCase().includes(search.toLowerCase());
  //     })
  //   );
  // }, [search, categoryData]);

  //columns of table
  const columns = [
    {
      name: "Image",
      selector: (row) => {
        return (
          <div>
            <img
              src={categoryImageURL + row.image}
              width="50"
              height="50"
              style={{ borderRadius: "50%" }}
              alt="categoryImg"
            />
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Category Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => {
        return (
          <div className="form-check form-switch mb-2">
            <input
              className="mt-0 form-check-input custom-control-input "
              type="checkbox"
              role="switch"
              checked={row.status}
              aria-label="Checkbox for following text input"
              onChange={() => handleChange(row.id)}
              aria-checked
            />
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <div>
            <FaEdit
              size={22}
              color="green"
              className="mx-2"
              style={{ cursor: "pointer" }}
              onClick={() => handleEdit(row)}
            />
            <MdOutlineDeleteOutline
              size={24}
              color="red"
              className="mx-2"
              style={{ cursor: "pointer" }}
              onClick={() => handleDelete(row.id)}
            />
          </div>
        );
      },
      sortable: true,
      minWidth: "500",
    },
  ];

  const Validation = () => {
    let categoryNameError = "";
    let isValid = true;

    if (!categoryName) {
      categoryNameError = "This field is required";
      isValid = false;
    }

    setCategoryNameError(categoryNameError);
    return isValid;
  };

  //function for editing Category
  const handleEdit = (data) => {
    console.log(data);
    setShow(true);
    setCategoryName(data.name);
    const updateFiles = [
      {
        preview: categoryImageURL + data.image,
      },
    ];
    setFiles(
      updateFiles.map((file) =>
        Object.assign(file, {
          files: file,
        })
      )
    );
    setCategoryEdit(data);
  };

  const handleUpdate = () => {
    const Valid = Validation();
    const categoryUpdate = [...categoryData];
    if (Valid) {
      setLoading(true);
      const index = categoryData.findIndex(
        (data) => data.id === categoryEdit.id
      );
      const formData = new FormData();
      formData.append("name", categoryName);
      formData.append("image", files[0]);
      formData.append("_method", "PUT");
      if (index >= 0) {
        categoryUpdate[index].name = categoryName;
        categoryUpdate[index].image = files[0];
        URL.post("/categories/" + categoryEdit.id, formData)
          .then((result) => {
            if (result.status === 200) {
              setLoading(false);
              categoryUpdate[index].image = result.data.data.category.image;
              setCategoryData(categoryUpdate);
              console.log(result.data.data.category);
              setCategoryName("");
              setFiles([]);
              setShow(false);
              toast.success(result.data.message);
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      }
    }
  };

  console.log(categoryData);

  //function to delete category
  const handleDelete = (ID) => {
    setDelShow(true);
    setDelID(ID);
  };

  const handleRemove = () => {
    setLoading(true);
    const categoryAfterDel = categoryData.filter((data) => data.id !== delID);
    URL.delete("/categories/" + delID)
      .then((result) => {
        if (result.status === 200) {
          setLoading(false);
          setCategoryData(categoryAfterDel);
          setDelID(null);
          setDelShow(false);
          toast.success(result.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleChange = (ID) => {
    setLoading(true);
    const statusUpdate = [...categoryData];
    const index = categoryData.findIndex((categories) => categories.id === ID);
    if (index >= 0) {
      URL.post("/categories/" + ID + "/status", {
        status: !statusUpdate[index].status,
      })
        .then((response) => {
          if (response.status === 200) {
            statusUpdate[index].status = response.data.data.category.status;
            setCategoryData(statusUpdate);
            setLoading(false);
            toast.success(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);

    URL.get("/categories", {
      params: {
        search: search,
      },
    })
      .then((result) => {
        if (result.status === 200) {
          setLoading(false);
          const data = result.data.data.categories;
          setCategoryData(data);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    if (!categoryData.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
    getCategoryData(1, newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(currentPage);
  };

  const handlePageChange = (page) => {
    getCategoryData(page, rowsPerPage);
    setCurrentPage(page);
  };

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShow(false);
    setDelShow(false);
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row mb-2">
                  <div className="col-md-6">
                    <h6 className="card-title">All Categories</h6>
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col-md-6">
                    <div className="input-group" style={{ width: "15rem" }}>
                      <div className="input-group-text">
                        <AiOutlineSearch />
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="navbarForm"
                        placeholder="Search here..."
                        value={search}
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <a href="/category">
                      <button className="btn btn-primary float-end">
                        Add Category
                      </button>
                    </a>
                  </div>
                </div>
                <div>
                  <DataTable
                    columns={columns}
                    data={categoryData}
                    defaultSortFieldId
                    pagination
                    paginationServer
                    paginationTotalRows={rows}
                    paginationDefaultPage={currentPage}
                    paginationPerPage={rowsPerPage}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    highlightOnHover
                    fixedHeader
                    fixedHeaderScrollHeight="500px"
                  />
                  {/*Edit Modal */}
                  <div>
                    <Modal
                      show={show}
                      onHide={handleClose}
                      backdrop="static"
                      keyboard={false}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Edit Category</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <form className="forms-sample">
                          <div className="row">
                            <div className="col-sm-12 mb-1">
                              <label htmlFor="name" className="form-label">
                                Category Name
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                id="name"
                                placeholder="Category Name"
                                required
                                value={categoryName}
                                onChange={(e) =>
                                  setCategoryName(e.target.value)
                                }
                              />
                            </div>
                            <div className="mb-3" style={{ color: "red" }}>
                              {categoryNameError}
                            </div>
                            <div className="col-sm-12 mb-3">
                              <label htmlFor="name" className="form-label">
                                Image
                              </label>
                              <DropzoneComponent
                                files={files}
                                setFiles={setFiles}
                              />
                            </div>
                          </div>
                        </form>
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                          onClick={handleClose}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleUpdate}
                        >
                          Edit
                        </button>
                      </Modal.Footer>
                    </Modal>
                  </div>

                  {/*Delete Modal */}
                  <DeleteModal
                    modalTitle={"Category"}
                    delShow={delShow}
                    handleClose={handleClose}
                    handleRemove={handleRemove}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}
