import React, { useState, useEffect, useRef, useCallback } from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import URL from "../../configuration/config";
import JoditEditor from "jodit-react";
import Loading from "../Elements/Loading";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import { AiOutlineSearch } from "react-icons/ai";
import DropzoneComponent from "../Dropbox/DropzoneComponent";
import { toast, ToastContainer } from "react-toastify";
import { useTheme } from "../../context/ThemeProvider";
import { brandImageURL } from "../../configuration/domainURL";
import DeleteModal from "../Elements/DeleteModal";

export default function ViewBrands() {
  const editorRef = useRef(null);
  const [brandData, setBrandData] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [nameError, setNameError] = useState("");
  const [delID, setDelID] = useState(null);
  const [brandEdit, setBrandEdit] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [delShow, setDelShow] = useState(false);
  const [search, setSearch] = useState("");
  const [files, setFiles] = useState([]);
  var striptags = require("striptags");
  // const [rows, setRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const { brandTotal, setBrandTotal } = useTheme();

  /** Get all brands */
  const getAllBrands = useCallback(
    (page, rowsPerPage) => {
      setLoading(true);
      URL.get("/brands", {
        params: {
          limit: rowsPerPage,
          page: page,
        },
      })
        .then((result) => {
          if (result.status === 200) {
            setLoading(false);
            var data = result.data.data.brands;
            console.log(data);
            setBrandData(data);
            setBrandTotal(result.data.data.totalRows);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    },
    [setLoading, setBrandTotal]
  );

  useEffect(() => {
    getAllBrands(currentPage, rowsPerPage);
  }, [currentPage, rowsPerPage, getAllBrands]);

  //columns of table
  const columns = [
    {
      name: "Image",
      selector: (row) => {
        return (
          <div>
            <img
              src={brandImageURL + row.image}
              width="50"
              height="50"
              style={{ borderRadius: "50%" }}
              alt="brandImg"
            />
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Brand Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => striptags(row.description),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => {
        return (
          <div className="form-check form-switch mb-2">
            <input
              type="checkbox"
              className="form-check-input"
              id="formSwitch1"
              checked={row.status}
              onChange={() => handleStatus(row.id)}
            />
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <div>
            <FaEdit
              size={22}
              color="green"
              className="mx-2"
              style={{ cursor: "pointer" }}
              onClick={() => handleEdit(row)}
            />
            <MdOutlineDeleteOutline
              size={24}
              color="red"
              className="mx-2"
              style={{ cursor: "pointer" }}
              onClick={() => handleDelete(row.id)}
            />
          </div>
        );
      },
      sortable: true,
    },
  ];

  const Validation = () => {
    let nameError = "";
    let isValid = true;

    if (!name) {
      nameError = "This field is required";
      isValid = false;
    }

    setNameError(nameError);
    return isValid;
  };

  const handleStatus = (ID) => {
    setLoading(true);
    const statusChange = [...brandData];
    const index = brandData.findIndex((data) => data.id === ID);
    if (index >= 0) {
      URL.post("/brands/" + ID + "/status", {
        status: !statusChange[index].status,
      }) //1
        .then((response) => {
          if (response.status === 200) {
            statusChange[index].status = response.data.data.brand.status; //0
            setBrandData(statusChange);
            setLoading(false);
            toast.success(response.data.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  //update function of brand
  const handleEdit = (data) => {
    setShow(true);
    setName(data.name);
    setDescription(data.description);
    const updateFiles = [
      {
        preview: brandImageURL + data.image,
      },
    ];
    setFiles(
      updateFiles.map((file) =>
        Object.assign(file, {
          files: file,
        })
      )
    );
    // setImage(data.image);
    setBrandEdit(data);
  };

  const handleUpdate = (event) => {
    event.preventDefault();
    const Valid = Validation();
    const brandUpdate = [...brandData];

    if (Valid) {
      setLoading(true);
      const index = brandData.findIndex((data) => data.id === brandEdit.id);
      const formData = new FormData();
      formData.append("name", name);
      formData.append("description", description);
      formData.append("image", files[0]);
      formData.append("_method", "PUT");
      if (index >= 0) {
        brandUpdate[index].name = name;
        brandUpdate[index].description = description;
        brandUpdate[index].image = files[0];
        URL.post("/brands/" + brandEdit.id, formData, {})
          .then((result) => {
            if (result.status === 200) {
              brandUpdate[index].image = result.data.data.brand.image;
              setBrandData(brandUpdate);
              setName("");
              setDescription("");
              setFiles([]);
              setShow(false);
              setLoading(false);
              toast.success(result.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      }
    }
  };

  //delete function of brand
  const handleDelete = (ID) => {
    setDelID(ID);
    setDelShow(true);
  };

  const handleRemove = () => {
    setLoading(true);
    const brandAfterDel = brandData.filter(
      (brandData) => brandData.id !== delID
    );
    URL.delete("/brands/" + delID)
      .then((result) => {
        if (result.status === 200) {
          setLoading(false);
          setBrandData(brandAfterDel);
          setDelID(null);
          setDelShow(false);
          toast.success(result.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    URL.get("/brands", {
      params: {
        search: search,
      },
    })
      .then((result) => {
        if (result.status === 200) {
          setLoading(false);
          const data = result.data.data.brands;
          setBrandData(data);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    if (!brandData.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
    getAllBrands(1, newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(currentPage);
  };

  const handlePageChange = (page) => {
    getAllBrands(page, rowsPerPage);
    setCurrentPage(page);
  };

  //Modal close
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShow(false);
    setDelShow(false);
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row mb-2">
                  <div className="col-md-6">
                    <h6 className="card-title">All Brands</h6>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-6">
                    <div className="input-group" style={{ width: "15rem" }}>
                      <div className="input-group-text">
                        <AiOutlineSearch />
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="navbarForm"
                        placeholder="Search here..."
                        value={search}
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <a href="/brand" className="btn btn-primary float-end">
                      Add Brand
                    </a>
                  </div>
                </div>
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={brandData}
                    defaultSortFieldId
                    pagination
                    paginationServer
                    paginationTotalRows={brandTotal}
                    paginationDefaultPage={currentPage}
                    paginationPerPage={rowsPerPage}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    highlightOnHover
                    fixedHeader
                    fixedHeaderScrollHeight="500px"
                  />
                  {/*Edit Modal */}
                  <Modal
                    show={show}
                    fullscreen
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Edit Brand</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <form className="forms-sample">
                        <div className="row">
                          <div className="col-sm-12 mb-1">
                            <label htmlFor="name" className="form-label">
                              Brand Name
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="name"
                              placeholder="Brand Name"
                              required
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                          <div className="mb-3" style={{ color: "red" }}>
                            {nameError}
                          </div>
                          <div className="col-sm-12 mb-3">
                            <label htmlFor="name" className="form-label">
                              Description
                            </label>
                            <JoditEditor
                              ref={editorRef}
                              value={description}
                              onChange={(content) => setDescription(content)}
                            />
                          </div>
                          <div className="col-sm-12 mb-3">
                            <label htmlFor="name" className="form-label">
                              Image
                            </label>
                            <DropzoneComponent
                              files={files}
                              setFiles={setFiles}
                            />
                          </div>
                        </div>
                      </form>
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                        onClick={handleClose}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleUpdate}
                      >
                        Edit
                      </button>
                    </Modal.Footer>
                  </Modal>

                  {/*Delete Modal */}
                  <DeleteModal
                    modalTitle={"Brand"}
                    delShow={delShow}
                    handleClose={handleClose}
                    handleRemove={handleRemove}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}
