import React, { useState, useRef } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import JoditEditor from "jodit-react";
import authURL from "../../configuration/config";
import DropzoneComponent from "../Dropbox/DropzoneComponent";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../Elements/Loading";
import { useTheme } from "../../context/ThemeProvider";

export default function ProductForm() {
  const editorRef = useRef(null);
  const [file, setFile] = useState([]);
  const [description, setDescription] = useState("");
  const [additionalDescription, setAdditionalDescription] = useState("");
  const [shipping, setShipping] = useState("");
  const [name, setName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [cost, setCost] = useState("");
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [nameError, setNameError] = useState("");
  const [quantityError, setQuantityError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [imgError, setImgError] = useState("");
  const [costError, setCostError] = useState("");
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const { categoryData, brandData } = useTheme();
  const imgRef = useRef();

  const Validation = () => {
    let nameError = "";
    let costError = "";
    let quantityError = "";
    let priceError = "";
    let imgError = "";
    let isValid = true;

    if (!name) {
      nameError = "This field is required";
      isValid = false;
    }

    if (!quantity) {
      quantityError = "This field is required";
      isValid = false;
    }

    if (!cost) {
      costError = "This field is required";
      isValid = false;
    }

    if (!price) {
      priceError = "This field is required";
      isValid = false;
    }

    if (imgRef.current !== undefined) {
      if (imgRef.current.clientWidth > 800) {
        imgError = "The width of image must be less than 800px";
        isValid = false;
      }

      if (imgRef.current.clientWidth !== imgRef.current.clientHeight) {
        imgError =
          "The width and height of image must be same Eg:(500px * 500px)";
        isValid = false;
      }
    }
    console.log(imgRef);

    setNameError(nameError);
    setQuantityError(quantityError);
    setCostError(costError);
    setPriceError(priceError);
    setImgError(imgError);
    return isValid;
  };
  console.log(imgRef);

  const handleCategory = (e) => {
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute("id");
    setCategory(option);
  };

  const handleBrand = (e) => {
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute("id");
    setBrand(option);
  };
  const handleAddProduct = () => {
    const isValid = Validation();
    if (isValid) {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", name);
      formData.append("cost", cost);
      formData.append("price", price);
      formData.append("quantity", quantity);
      formData.append("image", files[0]);
      file.forEach((file) => {
        formData.append("images[]", file);
      });
      formData.append("category_id", category);
      formData.append("brand_id", brand);
      formData.append("description", description);
      formData.append("additional_information", additionalDescription);
      formData.append("shipping_returns", shipping);
      formData.append("status", false);
      authURL
        .post("/products", formData,{ headers: {
          "Content-Type": "multipart/form-data",
        },})
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);
            setName("");
            setCost("");
            setPrice("");
            setQuantity("");
            setFiles([]);
            setFile([]);
            setBrand("");
            setCategory("");
            setDescription("");
            setAdditionalDescription("");
            setShipping("");
            toast.success(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const uploadSingleFile = (e) => {
    const selectedFiles = e.target.files;
    // Create an object for each selected file
    const imageObjects = Object.keys(selectedFiles).map((key) => {
      return selectedFiles[key];
    });

    // Update the images state with the image objects
    setFile([...file, ...imageObjects]);
  };

  const upload = (e) => {
    e.preventDefault();
  };

  const deleteFile = (e) => {
    const s = file.filter((item, index) => index !== e);
    setFile(s);
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="row">
          <div className="col-md-12 stretch-card">
            <div className="card">
              <div className="card-body">
                <h6 className="card-title">Add Product</h6>
                <form className="forms-sample">
                  <div className="row">
                    <div className="col-sm-12 mb-1">
                      <label htmlFor="name" className="form-label">
                        Product Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Product Name"
                        required
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                      />
                    </div>
                    <div className="mb-3" style={{ color: "red" }}>
                      {nameError}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 mb-3">
                      <label
                        htmlFor="exampleFormControlSelect1"
                        className="form-label"
                      >
                        Select Category
                      </label>
                      <select
                        className="form-select"
                        id="exampleFormControlSelect1"
                        onChange={handleCategory}
                        defaultValue={"DEFAULT"}
                      >
                        <option value={"DEFAULT"} disabled>
                          Select Category
                        </option>
                        {categoryData.map((item, i) => {
                          return (
                            <option value={item.name} key={i} id={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-sm-6 mb-3">
                      <label
                        htmlFor="exampleFormControlSelect1"
                        className="form-label"
                      >
                        Select Brand
                      </label>
                      <select
                        className="form-select"
                        id="exampleFormControlSelect1"
                        onChange={handleBrand}
                        defaultValue={"DEFAULT"}
                      >
                        <option value={"DEFAULT"} disabled>
                          Select Brand
                        </option>
                        {brandData.map((item, i) => {
                          return (
                            <option value={item.name} key={i} id={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4 mb-1">
                      <label htmlFor="name" className="form-label">
                        Product Quantity
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="name"
                        placeholder="Product Quantity"
                        required
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                      />
                      <div className="mb-3" style={{ color: "red" }}>
                        {quantityError}
                      </div>
                    </div>
                    <div className="col-sm-4 mb-1">
                      <label htmlFor="name" className="form-label">
                        Product Cost
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="name"
                        placeholder="Product Cost"
                        required
                        value={cost}
                        onChange={(e) => setCost(e.target.value)}
                      />
                      <div className="mb-3" style={{ color: "red" }}>
                        {costError}
                      </div>
                    </div>
                    <div className="col-sm-4 mb-1">
                      <label htmlFor="name" className="form-label">
                        Product Price
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="name"
                        placeholder="Product Price"
                        required
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                      />
                      <div className="mb-3" style={{ color: "red" }}>
                        {priceError}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 mb-3">
                      <label htmlFor="name" className="form-label">
                        Description
                      </label>
                      <JoditEditor
                        ref={editorRef}
                        value={description}
                        onBlur={(content) => setDescription(content)}
                      />
                    </div>
                    <div className="col-sm-12 mb-3">
                      <label htmlFor="name" className="form-label">
                        Additional Description
                      </label>
                      <JoditEditor
                        ref={editorRef}
                        value={additionalDescription}
                        onBlur={(content) => setAdditionalDescription(content)}
                      />
                    </div>
                    <div className="col-sm-12 mb-3">
                      <label htmlFor="name" className="form-label">
                        Shipping Returns
                      </label>
                      <JoditEditor
                        ref={editorRef}
                        value={shipping}
                        onBlur={(content) => setShipping(content)}
                      />
                    </div>
                    <div className="col-sm-12 mb-3">
                      <label htmlFor="name" className="form-label">
                        Main Image
                      </label>
                      <div>
                        {/* <input
                          type="file"
                          onChange={(e) => console.log(e.target.files[0])}
                        /> */}
                        <DropzoneComponent
                          files={files}
                          setFiles={setFiles}
                          imgRef={imgRef}
                        />
                        <div className="mb-3" style={{ color: "red" }}>
                          {imgError}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 mb-3">
                      <label htmlFor="name" className="form-label">
                        Multiple Images
                      </label>
                      <div className="multiple-images mb-3">
                        <div className="dropify-messsage">
                          <span className="file-icon">
                            <p className="mt-6" style={{ color: "#20212469" }}>
                              Drag and drop a file here or click
                            </p>
                          </span>
                        </div>
                        <input
                          type="file"
                          disabled={file.length === 5}
                          className="form-control"
                          onChange={uploadSingleFile}
                          accept=".jpg, .png, image/jpeg, image/png"
                          multiple
                        />
                      </div>
                      <div className="form-group preview">
                        <div className="row">
                          {file.length > 0 &&
                            file.map((item, index) => {
                              return (
                                <div className="col-sm-2 mb-2" key={index}>
                                  <div className="card">
                                    <img
                                      src={URL.createObjectURL(item)}
                                      key={item.name}
                                      alt={item.name}
                                      ref={imgRef}
                                      style={{
                                        aspectRatio: 1,
                                      }}
                                      // style={{
                                      //   width: "200px",
                                      //   height: "200px",
                                      // }}
                                    />
                                    {/* <img
                                      src={item}
                                      alt=""
                                      // width={174}
                                      // height={150}
                                      className="mb-3"
                                      style={{
                                        // objectFit: "cover",
                                        // resizeMode: "contain",
                                        // flex: 1,
                                        aspectRatio: 1,
                                      }}
                                    /> */}
                                    <div className="row">
                                      <div className="col-sm-6"></div>
                                      <div className="col-sm-6">
                                        <AiOutlineDelete
                                          type="button"
                                          onClick={() => deleteFile(index)}
                                          className="mb-2 float-end mx-3"
                                          style={{
                                            fontSize: "20px",
                                            color: "red",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="mb-3 d-flex flex-column align-items-end">
                  <button
                    type="button"
                    className="btn btn-primary mb-3"
                    onClick={upload}
                  >
                    Upload
                  </button>
                  <button
                    className="btn btn-primary"
                    type="submit"
                    onClick={handleAddProduct}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}
