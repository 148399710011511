export const BlogData = [
  {
    title: "Cras ornare tristique elit",
    author: "John Doe",
    image: "",
    category: "Lifestyle",
    date: "Nov 22 2018",
    description:
      "Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Suspendisse potenti. Sed egestas vulputate ...",
  },
  {
    title: "Vivamus vestibulum ntulla necante.",
    author: "John Doe",
    image: "",
    category: "Shopping",
    date: "Nov 21 2018",
    description:
      "Morbi purus libero, faucibus commodo quis, gravida id, est. Vestibulumvolutpat, lacus a ultrices sagittis, mi neque euismod dui ...",
  },
  {
    title: "Fusce pellentesque suscipit.",
    author: "John Doe",
    image: "",
    category: "Travel",
    date: "Nov 15 2022",
    description:
      "Praesent dapibus, neque id cursus faucibus, tortor neque egestas auguae, eu vulputate magna eros eu erat. Aliquam erat volutpat ...",
  },
  {
    title: "Aenean dignissim pellente squefelis.",
    author: "John Doe",
    image: "",
    category: "Travel",
    date: "Nov 11 2018",
    description:
      "Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus. Phasellus ultrices nulla quis nibh. Quisque lectus ...",
  },
  {
    title: "Quisque volutpat mattiseros.",
    author: "John Doe",
    image: "",
    category: "Hobbies",
    date: "Nov 10 2018",
    description:
      "Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus. Phasellus ultrices nulla quis nibh. Quisque lectus. Donec consectetuer ...",
  },
  {
    title: "Utaliquam sollicitudin leo.",
    author: "John Doe",
    image: "",
    category: "Travel",
    date: "Nov 11 2018",
    description:
      "Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Suspendisse potenti. Sed egestas vulputate ...",
  },
  {
    title: "Cras ornare tristique elit",
    author: "John Doe",
    image: "",
    category: "Lifestyle",
    date: "Nov 22 2018",
    description:
      "Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus. Phasellus ultrices nulla quis nibh. Quisque lectus ...",
  },
  {
    title: "Quisque a lectus.",
    author: "John Doe",
    image: "",
    category: "Hobbies",
    date: "Nov 8 2018",
    description:
      "Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Suspendisse potenti. Sed egestas vulputate ...",
  },
];
