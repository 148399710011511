import React, { useRef, useState } from "react";
import URL from "../../configuration/config";
import Loading from "../Elements/Loading";
import DropzoneComponent from "../Dropbox/DropzoneComponent";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function CategoryForm() {
  const [categoryName, setCategoryName] = useState("");
  const [nameError, setNameError] = useState("");
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const imgRef = useRef();

  console.log(files[0]);

  const Validation = () => {
    let nameError = "";
    let isValid = true;

    if (!categoryName) {
      nameError = "This field is required";
      isValid = false;
    }

    setNameError(nameError);
    return isValid;
  };

  const handleAddCategory = () => {
    const Valid = Validation();
    if (Valid) {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", categoryName);
      formData.append("image", files[0]);
      URL.post("/categories", formData, {})
        .then((result) => {
          if (result.status === 200) {
            setLoading(false);
            console.log(result.data.data);
            setCategoryName("");
            setFiles([]);
            toast.success(result.data.message);
          }
        })
        .catch((err) => {
          const { status, data } = err.response;
          if (status === 404) {
            setLoading(false);
            toast.error(data.data.name[0]);
          }
        });
    }
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h6 className="card-title">Add Category</h6>
                <form className="forms-sample">
                  <div className="row">
                    <div className="col-sm-12 mb-1">
                      <label htmlFor="name" className="form-label">
                        Category Name
                      </label>
                      <input
                        type="name"
                        className="form-control"
                        id="name"
                        placeholder="Category Name"
                        required
                        value={categoryName}
                        onChange={(e) => setCategoryName(e.target.value)}
                      />
                    </div>
                    <div className="mb-3" style={{ color: "red" }}>
                      {nameError}
                    </div>
                    <div className="col-sm-12 mb-3">
                      <label htmlFor="name" className="form-label">
                        Image
                      </label>
                      <DropzoneComponent
                        files={files}
                        setFiles={setFiles}
                        imgRef={imgRef}
                      />
                    </div>
                  </div>
                </form>
                <button
                  type="submit"
                  className="btn btn-primary float-end"
                  onClick={handleAddCategory}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}
