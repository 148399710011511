import React, { useState, useRef } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import JoditEditor from "jodit-react";

export default function BlogForm() {
  const editorRef = useRef(null);
  const [file, setFile] = useState([]);
  const [description, setDescription] = useState("");

  function uploadSingleFile(e) {
    let ImagesArray = Object.entries(e.target.files).map((e) =>
      URL.createObjectURL(e[1])
    );
    setFile([...file, ...ImagesArray]);
  }

  function deleteFile(e) {
    const s = file.filter((item, index) => index !== e);
    setFile(s);
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-12 stretch-card">
          <div className="card">
            <div className="card-body">
              <h6 className="card-title">Add Blogs</h6>
              <form className="forms-sample">
                <div className="row">
                  <div className="col-sm-6 mb-3">
                    <label htmlFor="name" className="form-label">
                      Blog Title
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="name"
                      placeholder="Blog Title"
                      required
                    />
                  </div>
                  <div className="col-sm-6 mb-3">
                    <label htmlFor="name" className="form-label">
                      Author Name
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="name"
                      placeholder="Author Name"
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 mb-3">
                    <label
                      htmlFor="exampleFormControlSelect1"
                      className="form-label"
                    >
                      Select Category
                    </label>
                    <select
                      className="form-select"
                      id="exampleFormControlSelect1"
                      defaultValue={"DEFAULT"}
                    >
                      <option value="DEFAULT" disabled>
                        Select Category
                      </option>
                      <option>Lifestyle</option>
                      <option>Shopping</option>
                      <option>Travel</option>
                      <option>Fashion</option>
                      <option>Hobbies</option>
                    </select>
                  </div>
                  <div className="col-sm-6 mb-3">
                    <label
                      htmlFor="exampleFormControlSelect1"
                      className="form-label"
                    >
                      Select Date
                    </label>
                    <div
                      className="input-group date datepicker"
                      id="datePickerExample"
                    >
                      <input type="text" className="form-control" />
                      <span className="input-group-text input-group-addon">
                        <i data-feather="calendar"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 mb-3">
                    <label htmlFor="name" className="form-label">
                      Description
                    </label>
                    <JoditEditor
                      ref={editorRef}
                      value={description}
                      onChange={(content) => {
                        setDescription(content);
                      }}
                    />
                  </div>
                  <div className="col-sm-12 mb-3">
                    <label htmlFor="name" className="form-label">
                      Main Image
                    </label>
                    <input
                      type="file"
                      id="myDropify"
                      accept=".jpg, .png, image/jpeg, image/png"
                    />
                  </div>
                  <div className="col-sm-12 mb-3">
                    <label htmlFor="name" className="form-label">
                      Multiple Images
                    </label>
                    <div className="multiple-images mb-3">
                      <div className="dropify-messsage">
                        <span className="file-icon">
                          <p className="mt-6" style={{ color: "#20212469" }}>
                            Drag and drop a file here or click
                          </p>
                        </span>
                      </div>
                      <input
                        type="file"
                        disabled={file.length === 5}
                        className="form-control"
                        onChange={uploadSingleFile}
                        accept=".jpg, .png, image/jpeg, image/png"
                        multiple
                      />
                    </div>
                    <div className="form-group preview">
                      <div className="row">
                        {file.length > 0 &&
                          file.map((item, index) => {
                            return (
                              <div className="col-sm-2 mb-2" key={item}>
                                <div className="card">
                                  <img
                                    src={item}
                                    alt=""
                                    width={174}
                                    height={150}
                                    className="mb-3"
                                    style={{ objectFit: "cover" }}
                                  />
                                  <div className="row">
                                    <div className="col-sm-6"></div>
                                    <div className="col-sm-6">
                                      <AiOutlineDelete
                                        type="button"
                                        onClick={() => deleteFile(index)}
                                        className="mb-2 float-end mx-3"
                                        style={{
                                          fontSize: "20px",
                                          color: "red",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div>
                <button className="btn btn-primary float-end" type="submit">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
