import React from "react";
import BlogForm from "../components/Blog/BlogForm";

export default function Blog() {
  return (
    <div className="page-content">
      <BlogForm />
    </div>
  );
}
