import React, { useRef, useState } from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { BlogData } from "../../data/BlogData";
import { AiOutlineEye } from "react-icons/ai";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import JoditEditor from "jodit-react";
import { AiOutlineDelete } from "react-icons/ai";
import DeleteModal from "../Elements/DeleteModal";

export default function ViewBlog() {
  const editorRef = useRef(null);
  const [description, setDescription] = useState("");
  // const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [delShow, setDelShow] = useState(false);
  // const [delID, setDelID] = useState(null);
  const [file, setFile] = useState([]);

  const columns = [
    {
      name: "Blog Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Image",
      selector: (row) => {
        return (
          <div>
            <img
              src={row.image}
              width="30"
              height="30"
              style={{ borderRadius: "50%" }}
              alt="BlogImg"
            />
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Author Name",
      selector: (row) => row.author,
      sortable: true,
    },
    {
      name: "Category",
      selector: (row) => row.category,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => {
        return (
          <div className="form-check form-switch mb-2">
            <input
              type="checkbox"
              className="form-check-input"
              id="formSwitch1"
            />
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <div>
            <AiOutlineEye size={24} className="mx-2" />
            <FaEdit
              size={22}
              color="green"
              className="mx-2"
              style={{ cursor: "pointer" }}
              onClick={() => handleEdit(row)}
            />
            <MdOutlineDeleteOutline
              size={24}
              color="red"
              className="mx-2"
              style={{ cursor: "pointer" }}
              onClick={() => handleDelete(row.id)}
            />
          </div>
        );
      },
      sortable: true,
      minWidth: 500,
    },
  ];

  function handleEdit(data) {
    setShow(true);
  }

  function handleDelete(ID) {
    // setDelID(ID);
    setDelShow(true);
  }
  function uploadSingleFile(e) {
    let ImagesArray = Object.entries(e.target.files).map((e) =>
      URL.createObjectURL(e[1])
    );
    setFile([...file, ...ImagesArray]);
  }

  function deleteFile(e) {
    const s = file.filter((item, index) => index !== e);
    setFile(s);
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShow(false);
    setDelShow(false);
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-md-6">
                  <h6 className="card-title">All Blogs</h6>
                </div>
                <div className="col-md-6">
                  <a href="/blog">
                    <button className="btn btn-primary float-end">
                      Add Blog
                    </button>
                  </a>
                </div>
              </div>{" "}
              <div className="table-responsive">
                <DataTable
                  columns={columns}
                  data={BlogData}
                  defaultSortFieldId
                  pagination={5}
                  paginationPerPage={10}
                  highlightOnHover
                  fixedHeader
                  fixedHeaderScrollHeight="500px"
                />
                {/*Edit Modal */}
                <Modal
                  show={show}
                  fullscreen
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Edit Blog</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <form className="forms-sample">
                      <div className="row">
                        <div className="col-sm-6 mb-3">
                          <label htmlFor="name" className="form-label">
                            Blog Title
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="name"
                            placeholder="Blog Title"
                            required
                          />
                        </div>
                        <div className="col-sm-6 mb-3">
                          <label htmlFor="name" className="form-label">
                            Blog Name
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="name"
                            placeholder="Blog Name"
                            required
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6 mb-3">
                          <label
                            htmlFor="exampleFormControlSelect1"
                            className="form-label"
                          >
                            Select Category
                          </label>
                          <select
                            className="form-select"
                            id="exampleFormControlSelect1"
                            defaultValue={"DEFAULT"}
                          >
                            <option value="DEFAULT" disabled>
                              Select Category
                            </option>
                            <option>Lifestyle</option>
                            <option>Shopping</option>
                            <option>Travel</option>
                            <option>Fashion</option>
                            <option>Hobbies</option>
                          </select>
                        </div>
                        <div className="col-sm-6 mb-3">
                          <label
                            htmlFor="exampleFormControlSelect1"
                            className="form-label"
                          >
                            Select Date
                          </label>
                          <div
                            className="input-group date datepicker"
                            id="datePickerExample"
                          >
                            <input type="text" className="form-control" />
                            <span className="input-group-text input-group-addon">
                              <i data-feather="calendar"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mb-3">
                          <label htmlFor="name" className="form-label">
                            Description
                          </label>
                          <JoditEditor
                            ref={editorRef}
                            value={description}
                            onChange={(content) => setDescription(content)}
                          />
                        </div>
                        <div className="col-sm-12 mb-3">
                          <label htmlFor="name" className="form-label">
                            Main Image
                          </label>
                          <input
                            type="file"
                            id="myDropify"
                            accept=".jpg, .png, image/jpeg, image/png"
                          />
                        </div>
                        <div className="col-sm-12 mb-3">
                          <label htmlFor="name" className="form-label">
                            Multiple Images
                          </label>
                          <div className="multiple-images mb-3">
                            <div className="dropify-messsage">
                              <span className="file-icon">
                                <p
                                  className="mt-6"
                                  style={{ color: "#20212469" }}
                                >
                                  Drag and drop a file here or click
                                </p>
                              </span>
                            </div>
                            <input
                              type="file"
                              disabled={file.length === 5}
                              className="form-control"
                              onChange={uploadSingleFile}
                              accept=".jpg, .png, image/jpeg, image/png"
                              multiple
                            />
                          </div>
                          <div className="form-group preview">
                            <div className="row">
                              {file.length > 0 &&
                                file.map((item, index) => {
                                  return (
                                    <div className="col-sm-2 mb-2" key={item}>
                                      <div className="card">
                                        <img
                                          src={item}
                                          alt=""
                                          width={174}
                                          height={150}
                                          className="mb-3"
                                          style={{ objectFit: "cover" }}
                                        />
                                        <div className="row">
                                          <div className="col-sm-6"></div>
                                          <div className="col-sm-6">
                                            <AiOutlineDelete
                                              type="button"
                                              onClick={() => deleteFile(index)}
                                              className="mb-2 float-end mx-3"
                                              style={{
                                                fontSize: "20px",
                                                color: "red",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                    <button type="button" className="btn btn-primary">
                      Edit
                    </button>
                  </Modal.Footer>
                </Modal>

                {/*Delete Modal */}
                <DeleteModal
                  modalTitle={"Blog"}
                  delShow={delShow}
                  handleClose={handleClose}
                  // handleRemove={handleRemove}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
