import React, { useState, useEffect } from "react";
import URL from "../../configuration/config";
import { useNavigate } from "react-router-dom";
import Loading from "../Elements/Loading";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { useLoginTheme } from "../../context/ThemeProvider";

export default function LoginDetails() {
  const [email, setEmail] = useState("");
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { login, setLogin } = useLoginTheme();

  useEffect(() => {
    // if (!login) {
    //   Cookies.set("token", null);
    // }
  }, [login]);

  //error message validation
  function Validate() {
    let emailError = " ";
    let passwordError = " ";

    let isValid = true;
    if (!email) {
      emailError = "Please enter your email";
      isValid = false;
    }
    if (!values.password) {
      passwordError = "Please enter your password";
      isValid = false;
    } else if (values.password.length < 5) {
      passwordError = "Password must be at least 6 characters";
      isValid = false;
    }
    setEmailError(emailError);
    setPasswordError(passwordError);
    return isValid;
  }

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  //URL for logging in
  const handleLogin = (e) => {
    e.preventDefault();
    const Valid = Validate();
    if (Valid) {
      setLoading(true);
      const formData = new FormData();
      formData.append("email", email);
      formData.append("password", values.password);
      URL.post("/login", formData, {})
        .then((result) => {
          if (result.status === 200) {
            // const token = JSON.stringify(result.data.data.token);
            const token = result.data.data.token;
            // Set the new token in the "Authorization" header for future requests
            URL.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            setLogin(true);
            localStorage.setItem("name", result.data.data.name);
            const expires = new Date();
            expires.setDate(expires.getDate() + 1); // Expires in 1 days
            // expires.setTime(expires.getTime() + 1 * 3600 * 1000);
            console.log(expires);
            Cookies.set("token", token, {
              expires: expires,
            });
            setLoading(false);
            navigate("/");
            window.location.reload(true);
          }
          toast.success("Login successful");
        })
        .catch((err) => {
          setLoading(false);
          if (err.response) {
            const { status, data } = err.response;
            if (status === 404) {
              setLoading(false);
              setError(data.data.error);
              // Cookies.set("token", null);
            }
          }
        });
    }
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="main-wrapper">
          <div
            className="page-wrapper full-page"
            style={{
              backgroundImage: 'url("/assets/images/pictures/login-3.jpg")',
              color: "#000",
              height: "100vh",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="page-content d-flex align-items-center justify-content-center">
              <div className="row w-100 mx-0 auth-page">
                <div className="col-md-8 col-xl-6 mx-auto">
                  <div className="card">
                    <div className="row">
                      <div className="col-md-4 pe-md-0">
                        <div className="auth-side-wrapper"></div>
                      </div>
                      <div className="col-md-8 ps-md-0">
                        <div className="auth-form-wrapper px-4 py-4">
                          <img
                            src="/assets/images/LookMeSVG.svg"
                            alt="logo"
                            width={160}
                            height={90}
                            className="mb-4"
                          />
                          <h5 className="text-muted fw-normal mb-4">
                            Log in to your account to continue.
                          </h5>
                          <div className="mb-3" style={{ color: "red" }}>
                            {error}
                          </div>
                          <form className="forms-sample">
                            <div className="mb-2">
                              <label htmlFor="userEmail" className="form-label">
                                Email address
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                id="userEmail"
                                placeholder="Email"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                            <div className="mb-3" style={{ color: "red" }}>
                              {emailError}
                            </div>
                            <div className="mb-2">
                              <label
                                htmlFor="userPassword"
                                className="form-label"
                              >
                                Password
                              </label>
                              <div className="textfield_icon">
                                <input
                                  type={
                                    values.showPassword ? "text" : "password"
                                  }
                                  value={values.password}
                                  className="form-control"
                                  id="userPassword"
                                  autoComplete="current-password"
                                  placeholder="Password"
                                  required
                                  onChange={handleChange("password")}
                                />
                                <span
                                  onClick={handleClickShowPassword}
                                  className="eye-icon"
                                >
                                  {values.showPassword ? (
                                    <AiFillEyeInvisible />
                                  ) : (
                                    <AiFillEye />
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="mb-3" style={{ color: "red" }}>
                              {passwordError}
                            </div>
                            <div className="mb-3 login-btn">
                              <button
                                className="btn btn-primary text-white"
                                onClick={handleLogin}
                              >
                                Login
                              </button>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-check mb-3">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="authCheck"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="authCheck"
                                  >
                                    Remember me
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-8">
                                <p className="text-end register-link">
                                  Don't have an account?{" "}
                                  <a href="/register">Register here</a>
                                </p>
                              </div>
                              {/* <div className="col-md-6">
                                <a
                                  href="/"
                                  className="d-block text-muted text-center float-end"
                                >
                                  Forgot Password?
                                </a>
                              </div> */}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}
