import React from "react";
import ViewBlog from "../components/Blog/ViewBlog";

export default function AllBlog() {
  return (
    <div className="page-content">
      <ViewBlog />
    </div>
  );
}
