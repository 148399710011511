import React, { useState, useEffect, useRef } from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { AiOutlineEye } from "react-icons/ai";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import JoditEditor from "jodit-react";
import URL from "../../configuration/config";
import Loading from "../Elements/Loading";
import { AiOutlineSearch } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DropzoneComponent from "../Dropbox/DropzoneComponent";
import { Link } from "react-router-dom";
import { useTheme } from "../../context/ThemeProvider";
import { nonImageURL, productImageURL } from "../../configuration/domainURL";
import DeleteModal from "../Elements/DeleteModal";

export default function ViewProduct() {
  const editorRef = useRef(null);
  const [description, setDescription] = useState("");
  const [additionalDescription, setAdditionalDescription] = useState("");
  const [shipping, setShipping] = useState("");
  const [name, setName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [cost, setCost] = useState("");
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState(null);
  const [brand, setBrand] = useState(null);
  const [nameError, setNameError] = useState("");
  const [quantityError, setQuantityError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [costError, setCostError] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [delShow, setDelShow] = useState(false);
  const [delID, setDelID] = useState(null);
  const [productOnEdit, setProductOnEdit] = useState("");
  const [productData, setProductData] = useState([]);
  const [search, setSearch] = useState("");
  const [files, setFiles] = useState([]);
  const [editCategory, setEditCategory] = useState("");
  const [editBrand, setEditBrand] = useState("");
  const [rows, setRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [imgError, setImgError] = useState("");
  const { categoryData, brandData } = useTheme();
  const imgRef = useRef();

  useEffect(() => {
    getProduct(currentPage, rowsPerPage);
  }, [currentPage, rowsPerPage]);

  const getProduct = (page, rowsPerPage) => {
    setLoading(true);
    URL.get("/products", {
      params: {
        limit: rowsPerPage,
        page: page,
      },
    })
      .then((result) => {
        if (result.status === 200) {
          setLoading(false);
          const data = result.data.data.products;
          setProductData(data);
          setRows(result.data.data.totalRows);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  //columns of table
  const columns = [
    {
      name: "Product Image",
      selector: (row) => {
        return (
          <div>
            {row.image === "no-image.png" ? (
              <img
                src={nonImageURL}
                width="30"
                height="30"
                style={{ borderRadius: "50%" }}
                alt="productImg"
              />
            ) : (
              <img
                src={row.image_url}
                width="30"
                height="30"
                style={{ borderRadius: "50%" }}
                alt="productImg"
              />
              // <img
              //   src={productImageURL + row.image}
              //   width="30"
              //   height="30"
              //   style={{ borderRadius: "50%" }}
              //   alt="productImg"
              // />
            )}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Product Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Category",
      selector: (row) => {
        return (
          <div>{row.category === null ? <p>N/A</p> : row.category.name}</div>
        );
      },
      sortable: true,
    },
    {
      name: "Brand",
      selector: (row) => {
        return <div>{row.brand === null ? <p>N/A</p> : row.brand.name}</div>;
      },
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      sortable: true,
    },
    {
      name: "Product Cost",
      selector: (row) => row.cost,
      sortable: true,
    },

    {
      name: "Product Price",
      selector: (row) => row.price,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => {
        return (
          <div className="form-check form-switch mb-2">
            <input
              type="checkbox"
              className="form-check-input"
              id="formSwitch1"
              checked={row.status}
              onChange={() => handleStatus(row.id)}
            />
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <div>
            <Link to={"/shop/" + row.id}>
              <AiOutlineEye size={24} className="mx-2 viewIcon" />
            </Link>
            <FaEdit
              size={22}
              color="green"
              className="mx-2"
              style={{ cursor: "pointer" }}
              onClick={() => handleEdit(row)}
            />
            <MdOutlineDeleteOutline
              size={24}
              color="red"
              className="mx-2"
              style={{ cursor: "pointer" }}
              onClick={() => handleDelete(row.id)}
            />
          </div>
        );
      },
      sortable: true,
      minWidth: 500,
    },
  ];

  const Validation = () => {
    let nameError = "";
    let costError = "";
    let quantityError = "";
    let priceError = "";
    let imgError = "";
    let isValid = true;

    if (!name) {
      nameError = "This field is required";
      isValid = false;
    }

    if (!quantity) {
      quantityError = "This field is required";
      isValid = false;
    }

    if (!cost) {
      costError = "This field is required";
      isValid = false;
    }

    if (!price) {
      priceError = "This field is required";
      isValid = false;
    }

    if (imgRef.current.clientWidth > 800) {
      imgError = "The width of image must be less than 800px";
      isValid = false;
    }

    if (imgRef.current.clientWidth !== imgRef.current.clientHeight) {
      imgError =
        "The width and height of image must be same Eg:(500px * 500px)";
      isValid = false;
    }
    console.log(imgRef.current.clientWidth === imgRef.current.clientHeight);

    setNameError(nameError);
    setQuantityError(quantityError);
    setCostError(costError);
    setPriceError(priceError);
    setImgError(imgError);
    return isValid;
  };

  const handleCategory = (e) => {
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute("id");
    setCategory(option);
    setEditCategory(e.target.value);
  };

  const handleBrand = (e) => {
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute("id");
    setBrand(option);
    setEditBrand(e.target.value);
  };

  const handleEdit = (row) => {
    setProductOnEdit(row);
    setShow(true);
    setName(row.name);
    setQuantity(row.quantity);
    setCost(row.cost);
    setPrice(row.price);
    setDescription(row.description);
    setAdditionalDescription(row.additional_information);
    setShipping(row.shipping_returns);
    setCategory(row.category ? row.category.id : null);
    setEditCategory(row.category ? row.category.name : null);
    setEditBrand(row.brand ? row.brand.name : null);
    setBrand(row.brand ? row.brand.id : null);
    if (row.image === "no-image.png") {
      const updateFiles = [
        {
          preview: { nonImageURL },
        },
      ];
      setFiles(
        updateFiles.map((file) =>
          Object.assign(file, {
            files: file,
          })
        )
      );
    } else {
      const updateFiles = [
        {
          preview: productImageURL + row.image,
        },
      ];
      setFiles(
        updateFiles.map((file) =>
          Object.assign(file, {
            files: file,
          })
        )
      );
    }
  };

  const handleUpdate = () => {
    const Valid = Validation();
    const productUpdate = [...productData];
    if (Valid) {
      setLoading(true);
      const index = productData.findIndex(
        (data) => data.id === productOnEdit.id
      );
      const formData = new FormData();
      formData.append("name", name);
      formData.append("cost", cost);
      formData.append("price", price);
      formData.append("quantity", quantity);
      formData.append("category_id", category);
      formData.append("brand_id", brand);
      formData.append("description", description);
      formData.append("additional_information", additionalDescription);
      formData.append("shipping_returns", shipping);
      formData.append("image", files[0]);
      formData.append("_method", "PUT");

      if (index >= 0) {
        productUpdate[index].name = name;
        productUpdate[index].cost = cost;
        productUpdate[index].price = price;
        productUpdate[index].quantity = quantity;
        productUpdate[index].category_id = category;
        productUpdate[index].brand_id = brand;
        productUpdate[index].description = description;
        productUpdate[index].additional_information = additionalDescription;
        productUpdate[index].shipping_returns = shipping;
      }

      URL.post("/products/" + productOnEdit.id, formData)
        .then((response) => {
          if (response.status === 200) {
            console.log(response);
            productData[index].category = response.data.data.product.category;
            productData[index].brand = response.data.data.product.brand;
            productData[index].image = response.data.data.product.image;
            setShow(false);
            setLoading(false);
            setProductData(productUpdate);
            toast.success(response.data.message);
          }
        })

        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }
  };

  const handleStatus = (ID) => {
    setLoading(true);
    const statusCopy = [...productData];
    const index = productData.findIndex((product) => product.id === ID);
    if (index >= 0) {
      URL.post("/products/" + ID + "/status", {
        status: !statusCopy[index].status,
      })
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);
            statusCopy[index].status = response.data.data.product.status;
            setProductData(statusCopy);
            toast.success(response.data.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    setSearch(event.target.value);
    URL.get("/products", {
      params: {
        search: search,
        limit: rowsPerPage,
        // page: page,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          const data = response.data.data.products;
          setProductData(data);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  //for deleting products
  const handleDelete = (ID) => {
    setDelID(ID);
    setDelShow(true);
  };

  const handleRemove = () => {
    setLoading(true);
    const productAfterDel = productData.filter(
      (productData) => productData.id !== delID
    );
    console.log(productAfterDel);
    URL.delete("/products/" + delID)
      .then((response) => {
        setLoading(false);
        setProductData(productAfterDel);
        setDelShow(false);
        toast.success(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    if (!productData.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
    getProduct(1, newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(currentPage);
  };

  const handlePageChange = (page) => {
    getProduct(page, rowsPerPage);
    setCurrentPage(page);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      /* A function that returns nothing. */
      return;
    }
    setShow(false);
    setDelShow(false);
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row mb-2">
                  <div className="col-md-6">
                    <h6 className="card-title">All Products</h6>
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col-md-6">
                    <div className="input-group" style={{ width: "15rem" }}>
                      <div className="input-group-text">
                        <AiOutlineSearch />
                      </div>
                      <input
                        type="text"
                        className="form-control mr-3"
                        id="navbarForm"
                        placeholder="Search here..."
                        value={search}
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <a href="/product">
                      <button className="btn btn-primary float-end">
                        Add Product
                      </button>
                    </a>
                  </div>
                </div>
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={productData}
                    defaultSortFieldId
                    pagination
                    paginationServer
                    paginationTotalRows={rows}
                    paginationDefaultPage={currentPage}
                    paginationPerPage={rowsPerPage}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    highlightOnHover
                    fixedHeader
                    fixedHeaderScrollHeight="500px"
                  />
                  {/*Edit Modal */}
                  <Modal
                    show={show}
                    fullscreen
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Edit Product</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <form className="forms-sample">
                        <div className="row">
                          <div className="col-sm-12 mb-1">
                            <label htmlFor="name" className="form-label">
                              Product Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder="Product Name"
                              required
                              value={name}
                              onChange={(event) => setName(event.target.value)}
                            />
                          </div>
                          <div className="mb-3" style={{ color: "red" }}>
                            {nameError}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6 mb-3">
                            <label
                              htmlFor="exampleFormControlSelect1"
                              className="form-label"
                            >
                              Select Category
                            </label>
                            <select
                              className="form-select"
                              id="exampleFormControlSelect1"
                              onChange={handleCategory}
                              value={editCategory ? editCategory : ""}
                            >
                              <option hidden>Select Category</option>
                              {categoryData.map((item, i) => {
                                return (
                                  <option key={i} id={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div className="col-sm-6 mb-3">
                            <label
                              htmlFor="exampleFormControlSelect1"
                              className="form-label"
                            >
                              Select Brand
                            </label>
                            <select
                              className="form-select"
                              id="exampleFormControlSelect1"
                              onChange={handleBrand}
                              value={editBrand ? editBrand : ""}
                            >
                              <option hidden>Select Brand</option>
                              {brandData.map((item, i) => {
                                return (
                                  <option key={i} id={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-4 mb-1">
                            <label htmlFor="name" className="form-label">
                              Product Quantity
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="name"
                              placeholder="Product Quantity"
                              required
                              value={quantity}
                              onChange={(e) => setQuantity(e.target.value)}
                            />
                            <div className="mb-3" style={{ color: "red" }}>
                              {quantityError}
                            </div>
                          </div>
                          <div className="col-sm-4 mb-1">
                            <label htmlFor="name" className="form-label">
                              Product Cost
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="name"
                              placeholder="Product Cost"
                              required
                              value={cost}
                              onChange={(e) => setCost(e.target.value)}
                            />
                            <div className="mb-3" style={{ color: "red" }}>
                              {costError}
                            </div>
                          </div>
                          <div className="col-sm-4 mb-1">
                            <label htmlFor="name" className="form-label">
                              Product Price
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="name"
                              placeholder="Product Price"
                              required
                              value={price}
                              onChange={(e) => setPrice(e.target.value)}
                            />
                            <div className="mb-3" style={{ color: "red" }}>
                              {priceError}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 mb-3">
                            <label htmlFor="name" className="form-label">
                              Description
                            </label>
                            <JoditEditor
                              ref={editorRef}
                              value={description}
                              onBlur={(content) => setDescription(content)}
                            />
                          </div>
                          <div className="col-sm-12 mb-3">
                            <label htmlFor="name" className="form-label">
                              Additional Description
                            </label>
                            <JoditEditor
                              ref={editorRef}
                              value={additionalDescription}
                              onBlur={(content) =>
                                setAdditionalDescription(content)
                              }
                            />
                          </div>
                          <div className="col-sm-12 mb-3">
                            <label htmlFor="name" className="form-label">
                              Shipping Returns
                            </label>
                            <JoditEditor
                              ref={editorRef}
                              value={shipping}
                              onBlur={(content) => setShipping(content)}
                            />
                          </div>
                          <div className="col-sm-12 mb-3">
                            <label htmlFor="name" className="form-label">
                              Main Image
                            </label>
                            <div className="mb-3" style={{ color: "red" }}>
                              {imgError}
                            </div>
                            <div>
                              <DropzoneComponent
                                files={files}
                                setFiles={setFiles}
                                imgRef={imgRef}
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                        onClick={handleClose}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleUpdate}
                      >
                        Edit
                      </button>
                    </Modal.Footer>
                  </Modal>

                  {/*Delete Modal */}
                  <DeleteModal
                    modalTitle={"Product"}
                    delShow={delShow}
                    handleClose={handleClose}
                    handleRemove={handleRemove}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}
