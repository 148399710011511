import React from "react";

export default function Sidebar() {
  return (
    <div>
      <nav className="sidebar">
        <div className="sidebar-header">
          <a href="/" className="sidebar-brand">
            <img
              src="/assets/images/LookMeSVG.svg"
              alt="logo"
              width={160}
              height={60}
            />
          </a>
          <div className="sidebar-toggler not-active">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="sidebar-body">
          <ul className="nav">
            <li className="nav-item nav-category">Menu</li>
            <li className="nav-item my-2">
              <a href="/" className="nav-link">
                <i className="link-icon" data-feather="box"></i>
                <span className="link-title">Dashboard</span>
              </a>
            </li>
            <li className="nav-item my-2">
              <a href="/order" className="nav-link">
                <i className="link-icon" data-feather="book"></i>
                <span className="link-title">Orders</span>
              </a>
            </li>
            <li className="nav-item my-2">
              <a
                className="nav-link"
                data-bs-toggle="collapse"
                href="#uiComponents"
                role="button"
                aria-expanded="false"
                aria-controls="uiComponents"
              >
                <i className="link-icon" data-feather="shopping-bag"></i>
                <span className="link-title">Product</span>
                <i className="link-arrow" data-feather="chevron-down"></i>
              </a>
              <div className="collapse" id="uiComponents">
                <ul className="nav sub-menu">
                  <li className="nav-item my-1">
                    <a href="/product" className="nav-link">
                      Add Product
                    </a>
                  </li>
                  <li className="nav-item my-1">
                    <a href="/shop" className="nav-link">
                      All Products
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item my-2">
              <a href="/featuredProducts" className="nav-link">
                <i className="link-icon" data-feather="package"></i>
                <span className="link-title">Featured Products</span>
              </a>
            </li>
            <li className="nav-item my-2">
              <a
                className="nav-link"
                data-bs-toggle="collapse"
                href="#emails"
                role="button"
                aria-expanded="false"
                aria-controls="emails"
              >
                <i className="link-icon" data-feather="list"></i>
                <span className="link-title">Category</span>
                <i className="link-arrow" data-feather="chevron-down"></i>
              </a>
              <div className="collapse" id="emails">
                <ul className="nav sub-menu">
                  <li className="nav-item my-1">
                    <a href="/category" className="nav-link">
                      Add Category
                    </a>
                  </li>
                  <li className="nav-item my-1">
                    <a href="/categories" className="nav-link">
                      All Categories
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item my-2">
              <a
                className="nav-link"
                data-bs-toggle="collapse"
                href="#brands"
                role="button"
                aria-expanded="false"
                aria-controls="brands"
              >
                <i className="link-icon" data-feather="tag"></i>
                <span className="link-title">Brand</span>
                <i className="link-arrow" data-feather="chevron-down"></i>
              </a>
              <div className="collapse" id="brands">
                <ul className="nav sub-menu">
                  <li className="nav-item my-1">
                    <a href="/brand" className="nav-link">
                      Add Brand
                    </a>
                  </li>
                  <li className="nav-item my-1">
                    <a href="/labels" className="nav-link">
                      All Brands
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item my-2">
              <a href="/users" className="nav-link">
                <i className="link-icon" data-feather="user"></i>
                <span className="link-title">Customer List</span>
              </a>
            </li>
            <li className="nav-item my-2">
              <a href="/contact" className="nav-link">
                <i className="link-icon" data-feather="phone-call"></i>
                <span className="link-title">Contact Us</span>
              </a>
            </li>
            <li className="nav-item my-2">
              <a
                className="nav-link"
                data-bs-toggle="collapse"
                href="#cms"
                role="button"
                aria-expanded="false"
                aria-controls="cms"
              >
                <i className="link-icon" data-feather="layers"></i>
                <span className="link-title">CMS</span>
                <i className="link-arrow" data-feather="chevron-down"></i>
              </a>
              <div className="collapse" id="cms">
                <ul className="nav sub-menu">
                  <li className="nav-item my-2">
                    <a
                      className="nav-link"
                      data-bs-toggle="collapse"
                      href="#advancedUI"
                      role="button"
                      aria-expanded="false"
                      aria-controls="advancedUI"
                    >
                      <span>Blogs</span>
                      <i className="link-arrow" data-feather="chevron-down"></i>
                    </a>
                  </li>
                  <div className="collapse" id="advancedUI">
                    <ul className="nav mega-menu">
                      <li className="nav-item my-1">
                        <a href="/blog" className="nav-link">
                          Add Blogs
                        </a>
                      </li>
                      <li className="nav-item my-1">
                        <a href="/posts" className="nav-link">
                          All Blogs
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* <li className="nav-item my-1">
                    <a
                      className="nav-link"
                      data-bs-toggle="collapse"
                      href="#team"
                      role="button"
                      aria-expanded="false"
                      aria-controls="team"
                    >
                      <span>Team</span>
                      <i className="link-arrow" data-feather="chevron-down"></i>
                    </a>
                  </li> */}
                  {/* <div className="collapse" id="team">
                    <ul className="nav sub-menu">
                      <li className="nav-item my-1">
                        <a href="/team" className="nav-link">
                          Add Team
                        </a>
                      </li>
                      <li className="nav-item my-1">
                        <a href="/partners" className="nav-link">
                          All Teams
                        </a>
                      </li>
                    </ul>
                  </div> */}
                  <li className="nav-item my-1">
                    <a href="/banner" className="nav-link">
                      Banner
                    </a>
                  </li>
                  <li className="nav-item my-1">
                    <a href="/slider" className="nav-link">
                      Slider
                    </a>
                  </li>
                  <li className="nav-item my-1">
                    <a href="/about" className="nav-link">
                      About
                    </a>
                  </li>
                  <li className="nav-item my-1">
                    <a href="/faq" className="nav-link">
                      FAQ
                    </a>
                  </li>
                  <li className="nav-item my-1">
                    <a href="/header" className="nav-link">
                      Header
                    </a>
                  </li>
                  <li className="nav-item my-1">
                    <a href="/terms" className="nav-link">
                      Terms and Conditions
                    </a>
                  </li>
                  <li className="nav-item my-1">
                    <a href="/privacy" className="nav-link">
                      Privacy Policy
                    </a>
                  </li>
                  <li className="nav-item my-1">
                    <a href="/refund" className="nav-link">
                      Refund Policy
                    </a>
                  </li>
                  <li className="nav-item my-1">
                    <a href="/return" className="nav-link">
                      Return Policy
                    </a>
                  </li>
                  <li className="nav-item my-1">
                    <a href="/footer" className="nav-link">
                      Footer
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item my-2">
              <a
                className="nav-link"
                data-bs-toggle="collapse"
                href="#settings"
                role="button"
                aria-expanded="false"
                aria-controls="settings"
              >
                <i className="link-icon" data-feather="settings"></i>
                <span className="link-title">Settings</span>
                <i className="link-arrow" data-feather="chevron-down"></i>
              </a>
              <div className="collapse" id="settings">
                <ul className="nav sub-menu">
                  <li className="nav-item my-1">
                    <a href="/systemsettings" className="nav-link">
                      System Settings
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}
