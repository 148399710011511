import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlinePlusCircle } from "react-icons/ai";
import Modal from "react-bootstrap/Modal";
import { FaEdit } from "react-icons/fa";
import { MdOutlineDeleteOutline } from "react-icons/md";
import URL from "../../configuration/config";
import DropzoneComponent from "../Dropbox/DropzoneComponent";
import Loading from "../Elements/Loading";
import { sliderImageURL } from "../../configuration/domainURL";
import DeleteModal from "../Elements/DeleteModal";

export default function SliderComponent() {
  const [show, setShow] = useState(false);
  const [sliderDetails, setSliderDetails] = useState([]);
  const [files, setFiles] = useState([]);
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [linkText, setLinkText] = useState("");
  const [titleError, setTitleError] = useState("");
  const [filesError, setFilesError] = useState("");
  const [tag, setTag] = useState("");
  const [loading, setLoading] = useState(false);
  const [delShow, setDelShow] = useState(false);
  const [delID, setDelID] = useState(null);
  const [totalRows, setTotalRows] = useState("");
  const [sliderOnEdit, setSliderOnEdit] = useState("");
  const [editShow, setEditShow] = useState(false);

  useEffect(() => {
    const getSliderDetails = () => {
      setLoading(true);
      URL.get("/sliders", {
        params: {
          limit: totalRows,
        },
      })
        .then((response) => {
          setSliderDetails(response.data.data.sliders);
          setLoading(false);
          setTotalRows(response.data.data.totalRows);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    };
    getSliderDetails();
  }, [totalRows]);

  const Validation = () => {
    let titleError = "";
    let filesError = "";
    let isValid = true;

    if (!title) {
      titleError = "This field is required";
      isValid = false;
    }

    if (files.length === 0) {
      filesError = "This field is required";
      isValid = false;
    }

    setTitleError(titleError);
    setFilesError(filesError);
    return isValid;
  };

  const handleAdd = () => {
    setShow(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      /* A function that returns nothing. */
      return;
    }
    setShow(false);
    setDelShow(false);
    setEditShow(false);
    setTitle("");
    setTag("");
    setFiles([]);
    setPrice("");
    setButtonText("");
    setLinkText("");
  };

  const handleAddSlider = () => {
    const Valid = Validation();
    if (Valid) {
      setLoading(true);
      const formData = new FormData();
      formData.append("title", title);
      formData.append("tag", tag);
      formData.append("image", files[0]);
      formData.append("starting_price", price);
      formData.append("button_text", buttonText);
      formData.append("button_link", linkText);

      URL.post("/sliders", formData,{ headers: {
          "Content-Type": "multipart/form-data",
        }})
        .then((response) => {
          if (response.status === 200) {
            setShow(false);
            const data = [...sliderDetails, response.data.data.slider];
            setSliderDetails(data);
            setLoading(false);
            setTitle("");
            setTag("");
            setFiles([]);
            setPrice("");
            setButtonText("");
            setLinkText("");
            toast.success(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleEdit = (data) => {
    setSliderOnEdit(data);
    setEditShow(true);
    setTitle(data.title);
    setTag(data.tag);
    setPrice(data.starting_price);
    setButtonText(data.button_text);
    setLinkText(data.button_link);
    const updateFiles = [
      {
        preview: sliderImageURL + data.image,
      },
    ];
    setFiles(
      updateFiles.map((file, i) =>
        Object.assign(file, {
          files: file,
        })
      )
    );
  };

  const handleUpdate = () => {
    const Valid = Validation();
    const sliderUpdate = [...sliderDetails];
    if (Valid) {
      setLoading(true);
      const index = sliderDetails.findIndex(
        (data) => data.id === sliderOnEdit.id
      );
      const formData = new FormData();
      formData.append("title", title);
      formData.append("tag", tag);
      formData.append("image", files[0]);
      formData.append("starting_price", price);
      formData.append("button_text", buttonText);
      formData.append("button_link", linkText);
      formData.append("_method", "PUT");
      if (index >= 0) {
        sliderUpdate[index].title = title;
        sliderUpdate[index].image = files[0];
        sliderUpdate[index].tag = tag;
        sliderUpdate[index].starting_price = price;
        sliderUpdate[index].button_text = buttonText;
        sliderUpdate[index].button_link = linkText;
        sliderUpdate[index].status = 0;

        URL.post("/sliders/edit/" + sliderOnEdit.id, formData)
          .then((response) => {
            if (response.status === 200) {
              console.log(response);
              setLoading(false);
              sliderUpdate[index].image = response.data.data.slider.image;
              //   setCategoryData(categoryUpdate)
              sliderUpdate[index].status = response.data.data.slider.status;
              setSliderDetails(sliderUpdate);
              setEditShow(false);
              setTitle("");
              setTag("");
              setFiles([]);
              setPrice("");
              setButtonText("");
              setLinkText("");
              toast.success(response.data.message);
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      }
    }
  };

  const handleDelete = (id) => {
    setDelID(id);
    setDelShow(true);
  };

  const handleRemove = () => {
    setLoading(true);
    const sliderAfterDel = sliderDetails.filter((data) => data.id !== delID);
    URL.delete("/sliders/" + delID)
      .then((response) => {
        if (response.status === 200) {
          setDelShow(false);
          setSliderDetails(sliderAfterDel);
          setLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleStatus = (row) => {
    const sliderUpdate = [...sliderDetails];
    const index = sliderDetails.findIndex((data) => data.id === row.id);
    var checking;
    if (row.status === 0) {
      checking = 1;
    } else {
      checking = 0;
    }
    const formData = new FormData();
    formData.append("title", row.title);
    formData.append("tag", row.tag);
    formData.append("starting_price", row.starting_price);
    formData.append("button_text", row.button_text);
    formData.append("button_link", row.button_link);
    formData.append("status", checking);
    formData.append("_method", "PUT");
    if (index >= 0) {
      // sliderUpdate[index].status = !sliderUpdate[index].status;
      URL.post("/sliders/edit/" + row.id, formData)
        .then((response) => {
          if (response.status === 200) {
            sliderUpdate[index].status = parseInt(
              response.data.data.slider.status
            );
            setSliderDetails(sliderUpdate);
            toast.success("Status Updated Successfully");
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="d-flex flex-row justify-content-between">
                  <div>
                    <h6 className="card-title page-title">Slider</h6>
                  </div>
                  <div className="card-icon" onClick={handleAdd}>
                    <AiOutlinePlusCircle />
                  </div>
                </div>
                {sliderDetails.map((item, index) => {
                  return (
                    <div className="card my-4" key={index}>
                      <div className="d-flex justify-content-end mt-3 mb-0">
                        <FaEdit
                          size={22}
                          color="green"
                          className="mx-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleEdit(item)}
                        />
                        <MdOutlineDeleteOutline
                          size={24}
                          color="red"
                          className="mx-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDelete(item.id)}
                        />
                      </div>
                      <div className="d-flex flex-column gap-4 flex-xl-row m-3 justify-content-between ">
                        <div className="slider-image">
                          <p className="py-2">Slider Image</p>
                          <div className="img-card">
                            <img
                              src={item.image_url}
                              alt="SliderImg"
                              className="image"
                            />
                          </div>
                        </div>
                        <div className="slider-table">
                          <div className="table-responsive pt-3">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th>Title</th>
                                  <th>Starting Price</th>
                                  <th>Tag</th>
                                  <th>Button Text</th>
                                  <th>Button Link</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{item.title}</td>
                                  {item.starting_price === null ? (
                                    <td>N/A</td>
                                  ) : (
                                    <td>Rs {item.starting_price}</td>
                                  )}
                                  {item.tag === null ? (
                                    <td>N/A</td>
                                  ) : (
                                    <td>{item.tag}</td>
                                  )}
                                  {item.button_text === null ? (
                                    <td>N/A</td>
                                  ) : (
                                    <td>{item.button_text}</td>
                                  )}
                                  {item.button_link === null ? (
                                    <td>N/A</td>
                                  ) : (
                                    <td>{item.button_link}</td>
                                  )}
                                  <td>
                                    <div className="form-check form-switch mb-2">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="formSwitch1"
                                        checked={item.status}
                                        onChange={() => handleStatus(item)}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {/* Add Slider Modal */}
          <Modal
            show={show}
            centered
            size="lg"
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Slider Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form className="forms-sample">
                <div className="row mb-1">
                  <label htmlFor="name" className="form-label">
                    Main Image
                  </label>
                  <div>
                    <DropzoneComponent
                      files={files}
                      setFiles={setFiles}
                      // imgRef={imgRef}
                    />
                  </div>
                  <div className="mb-3" style={{ color: "red" }}>
                    {filesError}
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col-sm-6 ">
                    <label htmlFor="name" className="form-label">
                      Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Title"
                      required
                      value={title}
                      onChange={(event) => setTitle(event.target.value)}
                    />
                    <div className="mb-3" style={{ color: "red" }}>
                      {titleError}
                    </div>
                  </div>
                  <div className="col-sm-6 ">
                    <label htmlFor="name" className="form-label">
                      Tag
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Tag"
                      required
                      value={tag}
                      onChange={(event) => setTag(event.target.value)}
                    />
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col-sm-4 ">
                    <label htmlFor="name" className="form-label">
                      Starting Price
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="name"
                      placeholder="Starting Price"
                      required
                      value={price}
                      onChange={(event) => setPrice(event.target.value)}
                    />
                  </div>
                  <div className="col-sm-4 mb-1">
                    <label htmlFor="name" className="form-label">
                      Button Text
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Button Text"
                      required
                      value={buttonText}
                      onChange={(event) => setButtonText(event.target.value)}
                    />
                  </div>
                  <div className="col-sm-4 mb-1">
                    <label htmlFor="name" className="form-label">
                      Button Link
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Button Link"
                      required
                      value={linkText}
                      onChange={(event) => setLinkText(event.target.value)}
                    />
                  </div>
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleAddSlider}
              >
                Add
              </button>
            </Modal.Footer>
          </Modal>
          {/*Edit Modal */}
          <Modal
            show={editShow}
            centered
            size="lg"
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Slider Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form className="forms-sample">
                <div className="row mb-1">
                  <label htmlFor="name" className="form-label">
                    Main Image
                  </label>
                  <div>
                    <DropzoneComponent
                      files={files}
                      setFiles={setFiles}
                      // imgRef={imgRef}
                    />
                  </div>
                  <div className="mb-3" style={{ color: "red" }}>
                    {filesError}
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col-sm-6 ">
                    <label htmlFor="name" className="form-label">
                      Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Title"
                      required
                      value={title}
                      onChange={(event) => setTitle(event.target.value)}
                    />
                    <div className="mb-3" style={{ color: "red" }}>
                      {titleError}
                    </div>
                  </div>
                  <div className="col-sm-6 ">
                    <label htmlFor="name" className="form-label">
                      Tag
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Tag"
                      required
                      value={tag}
                      onChange={(event) => setTag(event.target.value)}
                    />
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col-sm-4 ">
                    <label htmlFor="name" className="form-label">
                      Starting Price
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="name"
                      placeholder="Starting Price"
                      required
                      value={price}
                      onChange={(event) => setPrice(event.target.value)}
                    />
                  </div>
                  <div className="col-sm-4 mb-1">
                    <label htmlFor="name" className="form-label">
                      Button Text
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Button Text"
                      required
                      value={buttonText}
                      onChange={(event) => setButtonText(event.target.value)}
                    />
                  </div>
                  <div className="col-sm-4 mb-1">
                    <label htmlFor="name" className="form-label">
                      Button Link
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Button Link"
                      required
                      value={linkText}
                      onChange={(event) => setLinkText(event.target.value)}
                    />
                  </div>
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleUpdate}
              >
                Edit
              </button>
            </Modal.Footer>
          </Modal>
          {/*Delete Modal */}
          <DeleteModal
            modalTitle={"Slider Details"}
            delShow={delShow}
            handleClose={handleClose}
            handleRemove={handleRemove}
          />
        </div>
      )}

      <ToastContainer />
    </div>
  );
}
