import React from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useLoginDetailsTheme } from "../../context/ThemeProvider";

export default function Navbar() {
  const { name, email } = useLoginDetailsTheme();
  const navigate = useNavigate();
  function handleLogout() {
    Cookies.remove("token");
    // Cookies.set("token", null);
    localStorage.removeItem("name");
    navigate("/login");
  }

  return (
    <nav className="navbar">
      <a href="/" className="sidebar-toggler">
        <i data-feather="menu" />
      </a>
      <div className="navbar-content">
        {/* <form className="search-form">
          <div className="input-group">
            <div className="input-group-text">
              <i data-feather="search" />
            </div>
            <input
              type="text"
              className="form-control"
              id="navbarForm"
              placeholder="Search here..."
            />
          </div>
        </form> */}
        <ul className="navbar-nav">
          {/* <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="messageDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i data-feather="mail" />
            </a>
            <div
              className="dropdown-menu p-0"
              aria-labelledby="messageDropdown"
            >
              <div className="px-3 py-2 d-flex align-items-center justify-content-between border-bottom">
                <p>9 New Messages</p>
                <a href="" className="text-muted">
                  Clear all
                </a>
              </div>
              <div className="p-1">
                <a
                  href=""
                  className="dropdown-item d-flex align-items-center py-2"
                >
                  <div className="me-3">
                    <img
                      className="wd-30 ht-30 rounded-circle"
                      src="https://via.placeholder.com/30x30"
                      alt="userr"
                    />
                  </div>
                  <div className="d-flex justify-content-between flex-grow-1">
                    <div className="me-4">
                      <p>Leonardo Payne</p>
                      <p className="tx-12 text-muted">Project status</p>
                    </div>
                    <p className="tx-12 text-muted">2 min ago</p>
                  </div>
                </a>
                <a
                  href=""
                  className="dropdown-item d-flex align-items-center py-2"
                >
                  <div className="me-3">
                    <img
                      className="wd-30 ht-30 rounded-circle"
                      src="https://via.placeholder.com/30x30"
                      alt="userr"
                    />
                  </div>
                  <div className="d-flex justify-content-between flex-grow-1">
                    <div className="me-4">
                      <p>Carl Henson</p>
                      <p className="tx-12 text-muted">Client meeting</p>
                    </div>
                    <p className="tx-12 text-muted">30 min ago</p>
                  </div>
                </a>
                <a
                  href=""
                  className="dropdown-item d-flex align-items-center py-2"
                >
                  <div className="me-3">
                    <img
                      className="wd-30 ht-30 rounded-circle"
                      src="https://via.placeholder.com/30x30"
                      alt="userr"
                    />
                  </div>
                  <div className="d-flex justify-content-between flex-grow-1">
                    <div className="me-4">
                      <p>Jensen Combs</p>
                      <p className="tx-12 text-muted">Project updates</p>
                    </div>
                    <p className="tx-12 text-muted">1 hrs ago</p>
                  </div>
                </a>
                <a
                  href=""
                  className="dropdown-item d-flex align-items-center py-2"
                >
                  <div className="me-3">
                    <img
                      className="wd-30 ht-30 rounded-circle"
                      src="https://via.placeholder.com/30x30"
                      alt="userr"
                    />
                  </div>
                  <div className="d-flex justify-content-between flex-grow-1">
                    <div className="me-4">
                      <p>Amiah Burton</p>
                      <p className="tx-12 text-muted">Project deatline</p>
                    </div>
                    <p className="tx-12 text-muted">2 hrs ago</p>
                  </div>
                </a>
                <a
                  href=""
                  className="dropdown-item d-flex align-items-center py-2"
                >
                  <div className="me-3">
                    <img
                      className="wd-30 ht-30 rounded-circle"
                      src="https://via.placeholder.com/30x30"
                      alt="userr"
                    />
                  </div>
                  <div className="d-flex justify-content-between flex-grow-1">
                    <div className="me-4">
                      <p>Yaretzi Mayo</p>
                      <p className="tx-12 text-muted">New record</p>
                    </div>
                    <p className="tx-12 text-muted">5 hrs ago</p>
                  </div>
                </a>
              </div>
              <div className="px-3 py-2 d-flex align-items-center justify-content-center border-top">
                <a href="">View all</a>
              </div>
            </div>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="/"
              id="notificationDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i data-feather="bell" />
              <div className="indicator">
                <div className="circle" />
              </div>
            </a>
            <div
              className="dropdown-menu p-0"
              aria-labelledby="notificationDropdown"
            >
              <div className="px-3 py-2 d-flex align-items-center justify-content-between border-bottom">
                <p>6 New Notifications</p>
                <a href="/" className="text-muted">
                  Clear all
                </a>
              </div>
              <div className="p-1">
                <a
                  href="/"
                  className="dropdown-item d-flex align-items-center py-2"
                >
                  <div className="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3">
                    <i className="icon-sm text-white" data-feather="gift" />
                  </div>
                  <div className="flex-grow-1 me-2">
                    <p>New Order Recieved</p>
                    <p className="tx-12 text-muted">30 min ago</p>
                  </div>
                </a>
                <a
                  href=""
                  className="dropdown-item d-flex align-items-center py-2"
                >
                  <div className="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3">
                    <i
                      className="icon-sm text-white"
                      data-feather="alert-circle"
                    />
                  </div>
                  <div className="flex-grow-1 me-2">
                    <p>Server Limit Reached!</p>
                    <p className="tx-12 text-muted">1 hrs ago</p>
                  </div>
                </a>
                <a
                  href="/"
                  className="dropdown-item d-flex align-items-center py-2"
                >
                  <div className="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3">
                    <img
                      className="wd-30 ht-30 rounded-circle"
                      src="https://via.placeholder.com/30x30"
                      alt="userr"
                    />
                  </div>
                  <div className="flex-grow-1 me-2">
                    <p>New customer registered</p>
                    <p className="tx-12 text-muted">2 sec ago</p>
                  </div>
                </a>
                <a
                  href=""
                  className="dropdown-item d-flex align-items-center py-2"
                >
                  <div className="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3">
                    <i className="icon-sm text-white" data-feather="layers" />
                  </div>
                  <div className="flex-grow-1 me-2">
                    <p>Apps are ready for update</p>
                    <p className="tx-12 text-muted">5 hrs ago</p>
                  </div>
                </a>
                <a
                  href=""
                  className="dropdown-item d-flex align-items-center py-2"
                >
                  <div className="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3">
                    <i className="icon-sm text-white" data-feather="download" />
                  </div>
                  <div className="flex-grow-1 me-2">
                    <p>Download completed</p>
                    <p className="tx-12 text-muted">6 hrs ago</p>
                  </div>
                </a>
              </div>
              <div className="px-3 py-2 d-flex align-items-center justify-content-center border-top">
                <a href="">View all</a>
              </div>
            </div>
          </li> */}
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="/"
              id="profileDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                className="wd-30 ht-30 rounded-circle"
                src="/assets/images/pictures/avatar.jpg"
                alt="profile"
              />
            </a>
            <div
              className="dropdown-menu p-0"
              aria-labelledby="profileDropdown"
            >
              <div className="d-flex flex-column align-items-center border-bottom px-5 py-3">
                <div className="mb-3">
                  <img
                    className="wd-80 ht-80 rounded-circle"
                    src="/assets/images/pictures/avatar.jpg"
                    alt=""
                  />
                </div>
                <div className="text-center">
                  <p className="tx-16 fw-bolder">{name}</p>
                  <p className="tx-12 text-muted">{email}</p>
                </div>
              </div>
              <ul className="list-unstyled p-1">
                <li className="dropdown-item py-2">
                  <a href="/profile" className="text-body ms-0">
                    <i className="me-2 icon-md" data-feather="user" />
                    <span>Profile</span>
                  </a>
                </li>
                <li className="dropdown-item py-2" onClick={handleLogout}>
                  <a href="/login" className="text-body ms-0">
                    <i className="me-2 icon-md" data-feather="log-out" />
                    <span>Log Out</span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
}
